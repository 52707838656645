import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { motion } from 'framer-motion'

import SEO from '../components/SEO'
import Layout from '../layouts/Main'

export default ({ location, data }) => {
  const pageVariants = {
    initial: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        when: 'beforeChildren',
      },
    },
    exit: {
      y: 20,
      opacity: 0,
    },
  }

  const itemVariants = {
    initial: {
      y: 20,
      opacity: 0,
      transition: {
        type: 'spring',
        stiffness: 200,
      },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 150,
      },
    },
  }

  return (
    <Layout location={location}>
      <SEO title="Faisons connaissance" description="About me" />
      <motion.div
        className="p-about"
        initial="initial"
        animate="visible"
        variants={pageVariants}
      >
        <Container>
          <Row className="justify-content-center">
            <Col xl={{ span: 6, offset: 1 }}>
              <h1 className="name">
                <motion.span variants={itemVariants}>
                  Hello ! Je suis{' '}
                </motion.span>
                <motion.span variants={itemVariants}>
                  Charles Abedie{' '}
                </motion.span>
                <motion.span variants={itemVariants}>
                  UX/UI Designer &{' '}
                </motion.span>
                <motion.span variants={itemVariants}>
                  Creative Developer basé à Paris
                </motion.span>
              </h1>
              <Row>
                <Col xl={10}>
                  <motion.p variants={itemVariants}>
                    Je conçois des produits numériques et des sites web pour des
                    start-ups, des marques et des entrepreneurs aux projets
                    innovants.
                  </motion.p>
                  <motion.p variants={itemVariants}>
                    Polyvalent, j’aime travailler en ayant une vision d’ensemble
                    sur l’expérience. Mon travail combine une compréhension de
                    l'expérience utilisateur et de la conception visuelle.
                  </motion.p>
                </Col>
              </Row>
              <motion.p
                style={{ marginBottom: '0.5rem' }}
                variants={itemVariants}
              >
                Je suis disponible pour une éventuelle collaboration.&nbsp;
              </motion.p>
              <motion.a
                variants={itemVariants}
                className="custom-link"
                href="mailto:charlesabedie@gmail.com"
              >
                Contactez-moi
              </motion.a>
            </Col>
          </Row>
        </Container>
      </motion.div>
    </Layout>
  )
}
